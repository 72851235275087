import React, { useState, useContext } from 'react';
import { Header, Button } from 'suftnet-ui-kit';
import { FaBars, FaLock, FaUserPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Logo from './logo';
import Menu from './menu';
import useSticky from '../../../hooks/useSticky';
import useMobile from '../../../hooks/useMobile';
import MobilePanel from './mobile-panel';
import { ContainerWindow } from '../shared/app-container';


const SiteHeader = () => {
  const { isMobile } = useMobile();
  const sticky = useSticky(200);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const handlePanelToggle = () => {
    setIsPanelOpen(false);
  };
  return (
    <Header
      justifyContent="center"
      direction="column"
      alignItems="center"
      className={`header p-0 ${sticky ? 'sticky' : ''}`}
    >
      <ContainerWindow width={70}>
        <nav
          className={`flex-row justify-content-between align-items-center w-100 ${isMobile ? 'flex__mobile w-100 p-4' : ''
            } `}
        >
          <Logo isMobile={isMobile} />
          {!isMobile ? (
            <>
              <Menu />
              <Button
                className="rounded-circle-30 primary-solid-btn-0"
                Component={Link}
                to="/login"
              >
                <FaLock
                  size={16}
                  onClick={() => {
                    setShowPanel(true);
                  }}
                />{' '}
                <span className="ms-2">Login</span>
              </Button>
            </>
          ) : (
            <>
              <FaBars
                size={30}
                className="hamburger"
                onClick={() => {
                  setIsPanelOpen(true);
                }}
              />
            </>
          )}
          {isPanelOpen && (
            <MobilePanel
              isPanelOpen={isPanelOpen}
              handlePanelToggle={handlePanelToggle}
            />
          )}
        </nav>
      </ContainerWindow>

    </Header>
  );
};

export default SiteHeader;
