import React, { useState, useEffect } from 'react';
import { Layout, Content } from 'suftnet-ui-kit';
import SiteHeader from '../../header';
import { BigFooter, BottomFooter } from '../../footer';
import Scroll from '../../shared/scroll-up';
import Features from './feature';
import Hero from './hero';
import HowItWork from './howitwork';
import Faq from './faq';
import { AppJson } from '../../../../assets/data/appJson';
import WhyChooseUs from './why-choose-us';
import Pricing from './pricing';

const Home = () => {
  const [store, setStore] = useState({});

  useEffect(() => {
    setStore(AppJson)
  }, [AppJson])

  return (  
      <Layout >
        <SiteHeader />
        <Content
          justifyContent="start"
          direction="column"
          alignItems="start"
          className="mt-0"
        >
          <Scroll />
          <Hero {...store} />
          <WhyChooseUs />
          <Features {...store} />
          <HowItWork {...store} />
          <Pricing {...store} />
          <Faq />
        </Content>
        <BigFooter />
        <BottomFooter />
      </Layout>  
  );
};

export default Home;
