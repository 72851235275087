
import React from 'react';
import { Link } from 'react-router-dom';

const Menu = ({styles='menu'}) => { 
  return (
      <div className={styles}>
        <Link to="/">How it works</Link>
        <Link to="/pricing">Pricing</Link>
        <Link to="/features">Features</Link>
        <Link to="/faq">FAQS</Link>
        <Link to="/contact-us">Contact</Link>
      </div>
  )
};
export default Menu;
