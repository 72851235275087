export const DatepickerRangeValue = {
    from: undefined,
    to: undefined,
  };
  
  export const DatepickerSimpleValue = null;
  
  export const DatepickerValue = {
    from: undefined,
    to: undefined,
  };
  
  export const DatepickerOnChange = (value) => {
    // Function implementation
  };
  